<template>
  <div class="tab-container">
    <img @click="changeMenu" v-if="menuFolded" class="menu-icon" src="../assets/menu.png">
    <div v-else class="tab">
      <div class="btn-list">
        <button class="f_btn clear-chat" @click="clearChat">清空对话</button>
        <button class="f_btn clear-chat" @click="showPrompt = !showPrompt">提示语</button>
      </div>
      <!-- <div class="chat-box">
        <div @click="newChat" class="add-chat">新增对话</div>
        <div class="chat-list">对话列表</div>
      </div> -->
      <div class="func">
        <!-- <div v-if="darkMode" @click="changeColorMode" class="item">浅色模式</div> -->
        <!-- <div v-else @click="changeColorMode" class="item">深色模式</div> -->
        <!-- <div class="item" @click="changeAvatar">
          <img src="@/assets/gpt.png" alt="头像">
        </div> -->
        <div class="item" @click="logout">注销</div>
      </div>
      <img v-if="showMenuClose" @click="changeMenu" class="menu-close" src="../assets/close.png">
    </div>
    <PromptModal :show="showPrompt" @handlePrompt="handlePrompt"></PromptModal>

  </div>
</template>

<script setup>
import router from '@/router';
// import EventBus from '@/event';
import { state } from '@/state'
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import PromptModal from './PromptModal.vue';

const store = useStore()

//       isDark: false,
const menuFolded = ref(false)
const showMenuClose = ref(false)
const showPrompt = ref(false)

onMounted(() => {
  checkMenuFold()
})
function clearChat() {
  // 1. 清空页面数据， 2. 清空本地数据
  // this.$emit('clearChat')
  state.setClear(true)
  localStorage.removeItem('userMessages')
  localStorage.removeItem('gptMessages')
  localStorage.removeItem('messages')
  // localStorage.removeItem('prompt')
  // localStorage.removeItem('history')
  // EventBus.emit('clearChat')
}

function handlePrompt(text) {
  showPrompt.value = false
  // console.log('v1', text)
  const prompt = text && text.trim()
  localStorage.setItem('prompt', JSON.stringify(prompt))
}

function changeMenu() {
  menuFolded.value = !menuFolded.value
  showMenuClose.value = !showMenuClose.value
}

function logout() {
  store.dispatch('logout')
  // localStorage.removeItem('token') // 删除token
  // 更新state
  router.push({ path: 'login' })
  // router.push({ path: 'login', forceRefresh: true })
  // router.push({ name: 'login', forceRefresh: true })
}

function checkMenuFold() {
  let width = window.innerWidth
  menuFolded.value = width < 700 ? true : false
  window.onresize = () => {
    width = window.innerWidth
    menuFolded.value = width < 700 ? true : false
  }
}

// function changeAvatar() {
  
// }

    //   changeColorMode() {
    //     this.isDark = !this.isDark;
    //     if (this.isDark) {
    //       document.documentElement.style.setProperty('bg-color', '#f5222d');
    //     }
    //     console.log(this.isDark)
    //   }


// import { ref, defineExpose } from 'vue'
// import { useStore } from 'vuex';
// let isDark = ref(true);

// let isDark = false;

// const changeColorMode = () => {
//   // function changeColorMode() {
//   isDark.value = !isDark.value;
//   // isDark = !isDark;
//   console.log(isDark)
// }

// const store = useStore();
// const darkMode = computed(() => store.state.darkMode);
// // const darkMode = store.state.darkMode;

// const changeColorMode = () => {
//   store.commit('setDarkMode', !darkMode.value);
//   //   isDark.value = !isDark.value;
// };

// defineExpose({
//   isDark,
//   changeColorMode
// })

</script>

<style lang="less" scoped>
.tab-container {
  position: relative;
  // height: 100vh;
}

.menu-icon,
.menu-close {
  z-index: 999;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 4px;
  top: 4px;
  cursor: pointer;
}

.menu-close {
  left: 208px;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
}

.tab {
  box-sizing: border-box;
  padding: 24px;
  width: 200px;
  height: 100%;
  background-color: @black;
  color: @main-color;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: stretch;
}

.btn-list {
  display: flex;
  flex-direction: column;
}

.clear-chat {
  // border: 1px solid @white;
  margin-bottom: 20px;
  background-color: @white;
  color: @black;

  // border-radius: 6px;
  &:active {
    background-color: #bfbfbf;
  }
}


// .dark {
//   @bg-color: #212121;
// }

// .light {
//   @bg-color: #F5F5F5;
// }

.func {
  position: absolute;
  bottom: 40px;
  .item {
    margin: 10px 0;
    cursor: pointer;
      img {
    // margin-top: ;
    @img-w: 28px;
    @mt: (@input-h - @img-w);
    // margin-top: @input-h - @img-w ;
    // margin-top: (@input-h - @img-w) / 2;
    // margin-top: 3px;
    margin-top: @mt * 0.5;
    // margin-top: @mt;
    // margin-top: (40px - 10px) / 2;
    border-radius: 4px;
    width: @img-w;
    height: @img-w;
  }
  }
}

// .logout { }
</style>