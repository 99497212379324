<template>
  <div v-show="showAlert" class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-title">{{ message }}</div>
        <div class="modal-text" v-if="countdown > 0">将在
          <span> {{ countdown }} </span> 秒后跳转到首页
        </div>
        <button class="f_btn" @click="redirectToHome">首页</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from '@/router';
import { ref, watch, defineProps } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },
  message: {
    type: String,
    default: '操作成功'
  }
});

const showAlert = ref(props.show);
const countdown = ref(3)

const redirectToHome = () => {
  router.push('/')
}

watch(() => props.show, (newVal) => {
  showAlert.value = newVal;
  // 为true时说明弹窗弹出, 开始执行倒计时
  if (showAlert.value === true) {
    const intervalId = setInterval(() => {
      countdown.value--
      if (countdown.value === 0) {
        clearInterval(intervalId)
        showAlert.value = false
        redirectToHome()
      }
    }, 1000) // 每1秒执行一次--, 刚好3秒后跳转
  }
});
</script>

<style lang="less" scoped>
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


}

.modal-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;

  .modal-title {
    font-size: 26px;
  }

  .modal-text {
    margin: 20px 0 50px;

    span {
      color: @main-color;
    }
  }
}

@media screen and (max-width : 700px) {

  .modal-container {
    width: 250px;

    .modal-text {
      margin: 10px 0 30px;

      span {
        color: @main-color;
      }
    }
  }
}
</style>
