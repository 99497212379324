<template>
  <div class="container">
    <!-- <header-component /> -->
    <TabComponent />
    <MainComponent />
    <!-- <div>
      <router-link to="/login">Login</router-link>
    </div>
    <router-view></router-view> -->
  </div>
</template>

<script>

// import HeaderComponent from '../components/HeaderComponent.vue'
import MainComponent from '../components/MainComponent.vue'
import TabComponent from '../components/TabComponent.vue'

export default {
  data() {
    return {
      // text: '',
      // menuFolded: false,
    }
  },
  components: {
    'TabComponent': TabComponent,
    'MainComponent': MainComponent,
  },
  mounted() {

  },

  methods: {

  }
  // name: 'HomePage'
}

</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
</style>