<template>
  <div class="not-found">
    <div class="title">抱歉，页面不存在</div>
    <p class="countdown" v-if="countdown > 0">将在 {{ countdown }} 秒后跳转到首页</p>
    <button class="f_btn" @click="redirectToHome">返回首页</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const countdown = ref(3)
onMounted(() => {
  const intervalId = setInterval(() => {
    countdown.value--
    if (countdown.value === 0) {
      clearInterval(intervalId)
      redirectToHome()
    }
  }, 1000)
})

const redirectToHome = () => {
  router.push('/')
}

const router = useRouter()
</script>

<style scoped lang="less">
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .title {
    font-size: 30px;
  }

  .countdown {
    margin: 20px 0 50px;
  }
}
</style>
