// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import '@/assets/styles/fui.less'
import store from './store'
// Vue.config.productionTip = false

// store.dispatch('login')
const token = localStorage.getItem('token');
if (token) {
  store.commit('setToken', token);
}

createApp(App)
  .use(router)
  .use(store)
  .use(Vuex)
  .mount('#app')

// app.mount('#app')
// new Vue({
//   router,
//   render: h => h(App),
// }).$mount('#app')
