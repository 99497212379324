<template>
  <div class="f_container container">
    <div>
      <div class="f_form_title">登录</div>
      <form class="f_form" @submit.prevent="handleSubmit">
        <div class="f_form_item">
          <label for="email">邮箱</label>
          <input class="f_input" autocomplete="on" id="email" type="email" v-model="email" />
        </div>
        <div class="f_form_item">
          <label for="password">密码</label>
          <input class="f_input" autocomplete="on" minlength="8" maxlength="20" id="password" type="password"
            v-model="password" />
        </div>
        <ErrorMessage :message="errorMessage" />
        <!-- <BaseModal :show="showAlert" message="登录成功" /> -->
        <button class="f_btn f_btn_input" type="submit">登录</button>
      </form>
      <div class="login-tip">还没有账户？<a href="sign" class="f_link">注册</a></div>
    </div>

  </div>
</template>

<script setup>
import router from '@/router'
import axios from '@/api/axios.js'
import { ref } from 'vue'
import { useStore } from 'vuex'
import ErrorMessage from '@/components/ErrorMessage.vue'
// import BaseModal from '@/components/SignModal.vue.js'

const store = useStore()
const errorMessage = ref("")
// const showAlert = ref(true)
let email, password;
async function handleSubmit() {
  if (!email || !password) {
    errorMessage.value = "请输入邮箱和密码"
    return
  } else {
    errorMessage.value = ""
  }
  // 这里可以对用户输入的数据进行验证
  // console.log(email, password)

  // const res = await axios.post('/auth/login',
  //   { email, password })
  // const token = res.data.token
  // console.log('token', token)
  // console.log('r', res)
  // if (res.data.success === true) {
  //   store.dispatch('login', token)
  //   errorMessage.value = ""
  //   router.push({ name: 'home' })
  // } else { // 登录失败
  //   errorMessage.value = res.data.message
  // }
  await axios.post('/auth/login', { email, password })
    .then(res => {
      const token = res.data.token
      // console.log('token', token)
      // console.log('r', res)
      if (res.data.success === true) {
        store.dispatch('login', token)
        errorMessage.value = ""
        router.push({ name: 'home' })
      } else { // 登录失败
        errorMessage.value = res.data.message
      }
    })
    .catch(() => {
      errorMessage.value = "网络异常"
      // console.log('e', err)
    })

}
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
}
</style>