import { createStore } from "vuex";

export default createStore({
  state: {
    token: null, // 初始状态下用户信息为空
    darkMode: false,
    // isLoggedIn: false,
  },
  mutations: {
    setToken(state, token) {
      state.token = token; // 将用户信息保存到 Vuex store 中
      // state.isLoggedIn = true;
    },
    clearToken(state) {
      state.token = null;
      // state.isLoggedIn = false;
    },
    setDarkMode(state, value) {
      state.darkMode = value;
    }
  },
  actions: {
    login({ commit }, token) {
      commit('setToken', token);
      localStorage.setItem('token', token);
    },
    logout({ commit }) {
      commit('clearToken');
      localStorage.removeItem('token');
    }
  }
})