<template>
  <div class="f_container container">
    <div>
      <div class="f_form_title">开始使用GPT</div>
      <form class="f_form" @submit.prevent="handleSubmit">
        <div class="f_form_item">
          <label for="email">邮箱</label>
          <input @blur="checkEmail" class="f_input" autocomplete="on" id="email" type="email" v-model="email" />
          <ErrorMessage :message="emailError" />
          <!-- <p class="check-tip" v-if="emailError">{{ emailError }}</p> -->
        </div>
        <div class="f_form_item">
          <label for="password">密码</label>
          <input @blur="checkPassword" class="f_input" autocomplete="on" placeholder="请输入8~20位的密码" id="password"
            type="password" v-model="password" />
          <ErrorMessage :message="passwordError" />
        </div>
        <div class="f_form_item">
          <label for="code">邀请码</label>
          <input @input="checkInvitationCodeExists" class="f_input" id="code" type="text" v-model="code" />
          <ErrorMessage :message="codeError" />
        </div>
        <button class="f_btn f_btn_input" type="submit">注册</button>
      </form>
      <div class="login-tip">已经有账户了？<a href="login" class="f_link">登录</a></div>
      <SignModal :show="showAlert" message="注册成功" />
    </div>

  </div>
</template>

<script>
import axios from '@/api/axios.js';
import SignModal from '@/components/SignModal.vue';
import ErrorMessage from '@/components/ErrorMessage.vue'

export default {
  data() {
    return {
      email: '',
      password: '',
      code: '',
      emailError: '',
      // emailExists: false,
      // codeStatus: 1,
      passwordError: '',
      codeError: '',
      errorMessage: '',
      showAlert: false
    }
  },
  components: {
    SignModal,
    ErrorMessage
  },
  methods: {
    // 验证邮箱是否存在
    async checkEmail() {
      const valid = this.validateEmail(this.email)
      // 验证格式是否有效
      if (valid) {
        // 验证是否被使用
        const response = await axios.get(`/auth/email/${this.email}`);
        if (response.data.exists) {
          this.emailError = "该邮箱已存在"
        } else {
          this.emailError = ""
        }
      } else {
        this.emailError = "邮箱格式存在错误"
      }
    },

    checkPassword() {
      const l = this.password.length
      if (l < 8) {
        this.passwordError = "密码过短"
      } else if (l > 20) {
        this.passwordError = "密码过长"
      } else {
        this.passwordError = ""
      }
    },

    // 验证邀请码是否存在
    // 由于是在@input做的验证，因此假如输入邀请码注册后修改邮箱，再次点击注册，不会触发验证，
    // 但模型设置了邀请码唯一，因此不会出问题
    async checkInvitationCodeExists() {
      if (this.code.length > 10) {
        const response = await axios.get(`/auth/code/${this.code}`);
        const status = response.data.status
        if (status === -1) {
          this.codeError = "邀请码无效"
        } else if (status === 0) {
          this.codeError = "邀请码已被使用"
        } else {
          this.codeError = ""
        }
      } else {
        this.codeError = "邀请码无效"
      }
    },

    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    async handleSubmit() {
      // 不存在任何错误时才能提交
      this.checkEmail()
      this.checkPassword()
      this.checkInvitationCodeExists()

      if (!this.emailError && !this.passwordError && !this.codeError) {
        // let rightAuth = true
        // if (rightAuth) {
        await axios.post('/auth/sign',
          {
            email: this.email,
            password: this.password,
            code: this.code
          }).then(res => {
            // console.log('res', res)
            const data = res.data
            this.$store.dispatch('login', data.token)
            // localStorage.setItem('token', token)
            if (data.success === true) { // 注册成功
              this.showAlert = true
              // 在这加入倒计时
              // this.$router.push({ name: 'home' })
            } else { // 注册失败
              this.errorMessage = "注册失败，请重试"
            }
          }).catch(() => {
            this.errorMessage = "网络异常"
          })
      }
    },
  }
}

</script>

<style lang="less" scoped>
.container {
  height: 100vh;

  .check-tip {
    font-size: @error-size;
    color: @error;
    margin: 0;
    margin-left: 12px;
  }
}
</style>