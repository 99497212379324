import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/HomePage.vue'
import Sign from './views/SignPage.vue'
import Login from './views/LoginPage.vue'
import PageNotFound from './views/PageNotFound.vue'
import store from './store'

const routes = [
  // { path: '/', name: 'home', redirect: '/home' },
  // { path: '/', component: Home },
  // , requiresAuth: true 
  { path: '/', name: 'home', component: Home, meta: { title: 'FeJPT' } },
  // { path: '/home', name: 'home', component: Home, meta: { title: '首页', requiresAuth: true } },
  { path: '/sign', name: 'sign', component: Sign, meta: { title: '注册FeJPT' } },
  { path: '/login', name: 'login', component: Login, meta: { title: '登录FeJPT' } },
  { path: '/:pathMatch(.*)*', name: '404', component: PageNotFound, meta: { title: '页面不存在' } },
  // { path: '/about', component: About },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const authed = store.state.token !== null
  // 检查路由是否需要身份验证
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  // 如果用户未登录，且目标页面不是登录页，则跳转到登录页

  // console.log(authed)
  if (authed) { // 如果已登录
    if (to.name === 'login' || to.name === 'sign') { // 且目标页面是登录或注册页，则跳转到主页
      next({ name: 'home' });
    } else {
      next();
    }
  } else { // 未登录
    // console.log(to.name)
    if (to.name === 'home') {
      next({ name: 'login' });
      // 
    } else {
      next()
    }
  }
  // } else {
  //   next(); // 不需要身份验证，允许访问
  // }
})

export default router