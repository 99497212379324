<template>
  <div v-show="show" class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <img @click="closeModal" class="menu-close" src="../assets/close.png">
        <div class="modal-title">输入提示</div>
        <textarea ref="textarea" maxlength="1000" class="f_textarea modal-textarea" placeholder="GPT将始终在此基础上作答，除非再次修改提示"
          v-model="prompt" @keydown.enter.prevent="setPrompt"></textarea>
        <button class="f_btn" @click="setPrompt">确定</button>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { ref, watch, defineProps } from 'vue'
import { ref, watch, defineProps, defineEmits } from 'vue'
// import { ref, watch, defineProps, defineEmits, onMounted } from 'vue'

const emits = defineEmits(['handlePrompt'])
const prompt = ref(null)
// const wrapper = ref(null)
const textarea = ref(null)

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  }
});

// onMounted(() => {
//   textarea.value.focus()
// })

const show = ref(props.show)

watch(() => props.show, (newVal) => {
  show.value = newVal;
  const p = JSON.parse(localStorage.getItem('prompt'))
  if (p) {
    prompt.value = p
  } else {
    prompt.value = null
  }
  // if (show.value === true) {
    // console.log('tt', textarea.value)
    // setTimeout(() => {
    //   textarea.value.autofocus = true
    // }, 1000)
    // window.addEventListener('keydown', handleClose);
  // }
})

// function handleClose(event) {
//   // event.preventDefault()
//   console.log('ee', event.keyCode)
//   if (event.keyCode === 27) {
//     console.log('assd')
//     closeModal()
//   }
//   show.value = false
// }

function setPrompt() {
  // console.log(prompt.value)
  emits('handlePrompt', prompt.value)
  // const p = prompt.value && prompt.value.trim()
  // if (p) {
  //   localStorage.setItem('prompt', JSON.stringify(p))
  // }
  // show.value = false
}

function closeModal() {
  emits('handlePrompt', prompt.value)
  // show.value = false
}

</script>

<style lang="less" scoped>
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container {
  padding: 20px 0;
  // margin: 20px 0;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  align-items: center;
  width: 350px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;

  .modal-textarea {
    margin: 20px 0 20px;
    padding: 6px 8px;
    width: 80%;
    font-size: 16px;
    line-height: 20px;
    height: 64px;
    margin-bottom: 16px;
  }

  .modal-title {
    font-size: 26px;
  }

  .modal-text {
    margin: 20px 0 20px;

    span {
      color: @main-color;
    }
  }
}

.menu-close {
  z-index: 999;
  position: absolute;
  width: 18px;
  height: 18px;
  right: 6px;
  top: 6px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
  }
}

@media screen and (max-width : 700px) {
  .modal-container {
    width: 250px;

    .modal-text {
      margin: 10px 0 30px;

      span {
        color: @main-color;
      }
    }
  }
}
</style>
