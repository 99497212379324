<template>
  <div class="right-container">
    <div ref="scrollContainerRef" class="conversation">
      <!-- <div v-if="showModelChoose" class="model">
        <select class="f_select" v-model="selectedValue">
          <option class="op" value="3.5">GPT-3.5 Turbo 超快响应</option>
          <option class="op" value="4">GPT-4.0 Turbo 更加准确</option>
        </select>
      </div>
      <div v-else class="model">GPT-{{ model }} Turbo</div> -->
      <div class="model">GPT-{{ model }} Turbo</div>
      <!-- <div class="blink-container">1234111111111111
        <BaseBlink />
      </div> -->
      <div class="chat-item" v-for="idx in userMessages.length" :key="idx">
        <div class="user-card">
          <div class="user">{{ userMessages[idx - 1] }}</div>
          <img src="@/assets/user.png" />
        </div>
        <div class="gpt-card">
          <img src="@/assets/gpt.png" />
          <div class="gpt">
            <!-- idx 从 1 开始的，当 idx === length 时，对应的刚好是最后一条消息 -->
            <div>
              {{ gptMessages[idx - 1] }}
              <div v-if="isLoading && userMessages.length === idx" class="blink-container">
                <BaseBlink />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="error-message" v-if="errMessage">{{ errMessage }}</div>
      <!-- <button class="f_btn stop" @click="cancelEventSource">停止响应</button> -->
    </div>
    <div class="form-container">
      <!-- <button class="f_btn stop" v-if="isLoading" @click="cancelEventSource">停止</button> -->
      <form @submit.prevent="submitForm">
        <button class="f_btn stop" v-if="isLoading" @click="cancelEventSource">
          停止
        </button>
        <div class="form-item">
          <textarea id="myTextarea" maxlength="2000" autofocus ref="textareaRef" class="f_textarea" v-model="text"
            @input="resizeTextarea" @keydown="handleKeyDown" placeholder="说些什么吧"></textarea>
          <button :class="['f_btn send', isLoading ? 'disabled' : '']" type="submit">
            发送
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { fetchEventSource } from "@microsoft/fetch-event-source";
import router from "@/router";
import { state } from "@/state";

import { useStore } from "vuex";
import BaseBlink from "./BaseBlink.vue";
// import { onMounted, onUpdated, ref, computed, nextTick, watchEffect, onUnmounted } from 'vue';
import { onMounted, ref, computed, nextTick, watchEffect } from "vue";

const store = useStore();
const token = computed(() => store.state.token);
const [text, errMessage] = [ref(null), ref(null)];
const [userMessages, gptMessages] = [ref([]), ref([])];
const isLoading = ref(false); // 结果加载中
const [scrollContainerRef, textareaRef] = [ref(null), ref(null)];
// userMessages: ['111', '111', '111', '11', '3331111111wwwwwwwwwwwwwwwwwwwwwwwww1111111111111111111111111111333333333333', '11', '333333333333333', '11', '2222'],
// gptMessages: ['111', '111', '33112rr ', '2222222222222111111111111111111111111111111111111112222222', '333333333333333', '11', '333333333333333', '11', '2222'],
const showModelChoose = ref(true);
const selectedValue = ref("3.5");
const model = ref("3.5");

let ctrl,
  autoScroll = false;
let messages = [];
let previousWord,
  prompt = null;

onMounted(() => {
  scrollToBottom();
  window.addEventListener("resize", resizeTextarea);
  getAllLocalData();
});

watchEffect(() => {
  if (state.clear) {
    (userMessages.value = []), (gptMessages.value = []);
    errMessage.value = null;
    showModelChoose.value = true;
    model.value = "3.5";
    messages = [];
    previousWord = null;
    state.setClear(false);
  }
});

window.addEventListener("scroll", function () {
  autoScroll = false;
});

window.addEventListener("wheel", function () {
  autoScroll = false;
});

window.addEventListener("touchmove", function () {
  autoScroll = false;
});

// 从本地读取数据
function getAllLocalData() {
  userMessages.value = getFromLocal("userMessages") || [];
  gptMessages.value = getFromLocal("gptMessages") || [];
  model.value = getFromLocal("model") || "3.5";
  selectedValue.value = model.value;
  prompt = getFromLocal("prompt");
  // console.log(prompt)
  if (!prompt) prompt = null;

  messages = getFromLocal("messages") || [];
  // 如果 userMessages 比 gptMessages 长，说明上次输入未获得结果，将上次输入保存到输入框
  if (userMessages.value.length > gptMessages.value.length) {
    text.value = userMessages.value.pop();
    messages.pop();
    // 上次未正常对话，读取上次聊天后自适应高度
    nextTick(() => {
      var textarea = document.getElementById("myTextarea");
      textarea.style.height = textarea.scrollHeight + "px";
    });
  }

  if (userMessages.value.length > 0) {
    // 存在对话，说明设置过了版本
    showModelChoose.value = false;
  } else {
    // 不存在对话，则初始化为3.5，而不管本地存储的记录
    selectedValue.value = "3.5";
  }
}

// 限制记忆数量size
function checkMessages() {
  let len = messages.length;
  // console.log('pppp', prompt)
  let size;
  size = model.value === "3.5" ? 11 : 6;
  if (len > size) {
    // 超过 size 的删除
    if (prompt === null) {
      // 不存在prompt 直接删除
      messages.splice(0, len - size);
      return;
    }
    const p = { role: "system", content: prompt };
    for (let i = 0, j = 0; j < len - size; i++) {
      // console.log('s', messages[i], p)
      // 消息和提示p不同时才删除消息
      if (JSON.stringify(messages[i]) !== JSON.stringify(p)) {
        // 对象不能直接比较
        messages.splice(i, 1);
        j++;
      }
    }
  }
}

// 存储到本地存储中的数据必须是字符串类型，所以在存储之前需要使用JSON.stringify将数组转换为字符串。
function saveToLocal(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

// 在获取存储的数组时，需要使用JSON.parse将字符串转换为数组。
function getFromLocal(key) {
  return JSON.parse(localStorage.getItem(key));
}

function handleKeyDown(event) {
  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault(); // 阻止默认行为，防止换行
    submitForm();
  } else if (event.key === "Enter" && event.shiftKey) {
    insertNewLine(event); // 插入换行符的逻辑
  }
}

// 实现换行，即使当前光标后面有文本，也能换行
function insertNewLine(event) {
  // 获取当前文本框的值和光标位置
  const textarea = event.target;
  const value = textarea.value;
  const position = textarea.selectionStart;

  // 分割当前文本框的值
  const before = value.substring(0, position);
  const after = value.substring(position, value.length);
  // console.log('b', before, 'a', after)
  // 在光标位置处插入换行符
  text.value = `${before}\n${after}`;

  // 将光标位置设置为插入换行符后的位置
  nextTick(() => {
    textarea.selectionStart = position;
    textarea.selectionEnd = position + 1;
  });
}

function scrollToBottom() {
  scrollContainerRef.value.scrollTop = scrollContainerRef.value.scrollHeight;
}

function resizeTextarea() {
  // this.$refs.textarea.style.height = 'auto';
  // this.$refs.textarea.style.width = 'auto';
  if (textareaRef) {
    if (text.value === "") {
      textareaRef.value.style.height = "32px";
    } else {
      textareaRef.value.style.height = "0px";
    }
    textareaRef.value.style.height = textareaRef.value.scrollHeight + "px";
  }
}

function submitForm() {
  const t = text.value && text.value.trim();
  if (isLoading.value || !t) return; // 如果在加载中，无法再次提交，为空也无法提交

  showModelChoose.value = false;
  // console.log('s', selectedValue.value)
  model.value = selectedValue.value;
  saveToLocal("model", model.value);

  messages.push({ role: "user", content: t });
  userMessages.value.push(t);
  saveToLocal("userMessages", userMessages.value);
  text.value = "";
  scrollToBottom(); // 用户输入后滚动

  textareaRef.value.style.height = "32px";
  // resizeTextarea()
  isLoading.value = true;
  // console.log('提交的token', token.value)
  // if (messages.length % 2 === 0) { // 还未获得回复，应该是奇数，如果是偶数说明重复调用了接口，但是api调用失败，且再次发送相同内容的情况
  //   messages.pop()
  // }
  checkPrompt();
  eventSourceChat();
}

// 测试发现，prompt放在最开头会不起作用， 因此每次重新push
function checkPrompt() {
  prompt = getFromLocal("prompt");
  if (prompt === null) return; // 没有设置过prompt，直接返回
  // 找到原本的prompt，如果与新的prompt不同，则删除并替换新的，否则，只删除
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].role === "system") {
      messages.splice(i, 1);
      if (prompt === "") {
        //原本有prompt，现在清空了，这时删除该messages[i]
        return;
      } else {
        // 有新的prompt，则修改
        messages.splice(i, 1);
        messages.push({ role: "system", content: prompt });
        return;
      }
      // else {
      //   // 如果相同，直接返回
      //   return
      // }
    }
  }
  // 遍历完，未找到system，且prompt不为空，说明还没有，应该加入
  messages.push({ role: "system", content: prompt });
}

function cancelEventSource() {
  ctrl.abort();
  isLoading.value = false;
}

async function eventSourceChat() {
  // console.log('m', messages)
  ctrl = new AbortController();
  // await fetchEventSource("http://localhost:3001/api/chat", {
  await fetchEventSource("/api/chat", {
    openWhenHidden: true, // 切换页面时仍然保持传输
    method: "POST",
    headers: {
      Authorization: `Bearer ${token.value}`,
      "Content-Type": "application/json",
    },
    // responseType: 'stream',
    body: JSON.stringify({
      messages,
      model: model.value,
    }),
    signal: ctrl.signal,
    onopen() {
      gptMessages.value.push("");
      autoScroll = true;
    },
    onmessage(event) {
      if (autoScroll) {
        scrollToBottom();
        // window.scrollTo(0, document.body.scrollHeight);
      }
      const msg = JSON.parse(event.data);
      const status = msg.status;
      const l = gptMessages.value.length;
      if (status === "success") {
        const word = msg["content"];
        // console.log("word", word);
        // console.log(`-${previousWord}-${word}-`)
        if (word === "" && previousWord === "") {
          // 连续两个空格插入换行(空格传递过来变成了空串)
          // console.log(word, previousWord, '换行')
          gptMessages.value[l - 1] += "\n";
        } else {
          gptMessages.value[l - 1] += word;
        }
        previousWord = word;
      } else {
        if (status === "expired") {
          // 登录过期
          errMessage.value = "登录过期，请重新登录";
          store.dispatch("logout"); // 清除本地token
          setTimeout(() => {
            router.push({ name: "login" });
          }, 2000); // 2秒后跳转到登录界面
          return;
        } else {
          // 其他网络错误
          // gptMessages.value[l - 1] += '[出现错误]'
          // gptMessages.value.push('[出现错误]');
          if (status === "ECONNABORTED") {
            // 连接超时
            errMessage.value = "连接超时请重试!";
          } else if (status === "ECONNREFUSED" || status === "ECONNRESET") {
            // 服务器异常
            errMessage.value = "服务异常请稍后再试!";
          } else {
            // 其他都算作网络异常
            errMessage.value = "网络异常，请检查网络连接!";
          }
          setTimeout(() => {
            location.reload();
          }, 600)
        }
      }
    },
    onclose() {
      const content = gptMessages.value[gptMessages.value.length - 1];
      // if (content === "" || content === "[出现错误]") {
      // if (content === "[出现错误]") {
      if (content === "") {
        // console.log('a', gptMessages.value)
        gptMessages.value.pop(); // 未正常获得回复
      } else {
        messages.push({ role: "assistant", content: content });
      }

      autoScroll = false; // 回复完成后，恢复初始状态
      // console.log('autoScroll', autoScroll)
      previousWord = null;
      // console.log('closed')
      // if the server closes the connection unexpectedly, retry:
      // throw new RetriableError();
      isLoading.value = false;
      saveToLocal("gptMessages", gptMessages.value);
      saveToLocal("messages", messages);
      checkMessages();
    },
    onerror() {
      // console.log('err', err)
      isLoading.value = false; // 出现错误，也停止显示加载中
      autoScroll = false; // 回复完成后，恢复初始状态
      // console.error(error);
      gptMessages.value.push("[出现错误]");
      errMessage.value = "网络异常，请刷新重试!";
    },
  });
}
</script>

<style lang="less" scoped>
.blink-container {
  display: inline;
  position: relative;
  line-height: 30px;
}

.right-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: @bg-color;
  position: relative;
  width: 100%;
  flex-grow: 1;
}

.conversation {
  padding: 20px 15%;
  flex: 1;
  width: 60%;
  margin-bottom: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .model {
    align-self: center;
    margin-bottom: 10px;
  }

  // max-width: 800px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
}

// 停止按钮
.stop {
  margin-left: auto;
  // margin-top: -150px;
  margin-bottom: 30px;
  height: 34px;
  align-self: center;
  color: @white;
  background-color: #424242;

  &:active {
    background-color: #ddd;
    color: #212121;
  }
}

.chat-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@radius: 10px;

.gpt-card {
  align-self: flex-start;
}

.user-card,
.gpt-card {
  margin: 5px 0;
  display: flex;
  max-width: 90%;

  // width: 80%;
  img {
    // margin-top: ;
    @img-w: 28px;
    @mt: (@input-h - @img-w);
    // margin-top: @input-h - @img-w ;
    // margin-top: (@input-h - @img-w) / 2;
    // margin-top: 3px;
    margin-top: @mt * 0.5;
    // margin-top: @mt;
    // margin-top: (40px - 10px) / 2;
    border-radius: 4px;
    width: @img-w;
    height: @img-w;
  }
}

.user {
  position: relative;
  right: 0;
  background-color: #e9e9e9;
  // background-color: #f1f5fb;
  border-radius: @radius @radius 0 @radius;
}

.gpt {
  // float: left;
  background-color: #e5f6e5;
  // background-color: #f1ffef;
  // background-color: #f9e4cb;
  // background-color: @main-color;
  border-radius: @radius @radius @radius 0;
  // 让内部闪烁样式居中
  position: relative;
  // border-radius: @radius;
}

.user,
.gpt {
  display: inline-block;
  margin: 0 8px;
  padding: 3px 10px;
  line-height: 30px;
  // width: 80%;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  font-family: inherit;
}

.error-message {
  align-self: center;
  text-align: center;
  color: @error;
  border: 1px solid @white;
  // background-color: @white;
  background-color: #fff;
  height: 40px;
  line-height: 40px;
  width: fit-content;
  min-width: 50%;
  border-radius: 8px;
  padding: 0 10px;
}

.form-container {
  box-sizing: border-box;
  min-width: 300px;
  // background-color: @white;
  // width: 50vw;
  // height: 40vh;
  // z-index: 111;
  // width: 100%;
  // padding: 0 10%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
}

form {
  width: 60%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  position: absolute;
  bottom: 50px;

  // left: 50%;
  // transform: translate(-50%);
  textarea {
    font-family: inherit;
    background-color: #fff;
    border: none;
    outline: none;
    box-sizing: border-box;

    height: 32px;
    padding: 6px 12px;
    // min-width: 500px;
    line-height: 20px;
    // line-height: @textarea-h;
    margin-right: 20px;
    font-size: 16px;
    flex: 1;
  }

  .form-item {
    display: flex;
  }

  .prompt-item {
    display: flex;
    align-self: flex-end;
  }

  // .send {}
  .prompt {
    // border: 1px solid @black;
    color: @black;
    @size: 20px;
    width: @size;
    height: @size;
    line-height: @size;
    font-size: 14px;
    padding: 0;
    margin: 0 calc((@btn-w - @size) / 2);
    // margin-right: calc(@btn-w - @size );
    // position: absolute;
    // right: 0;
    // margin-right: ;
    margin-bottom: 16px;
    background-color: #d0d0d0;
    color: @black;
    border-radius: 50%;
    align-self: flex-end;

    // border-radius: 6px;
    &:active {
      background-color: #bbb;
    }
  }
}

.disabled {
  pointer-events: none;
  background-color: #ccc;
  color: #919191;
}

@media screen and (max-width: 700px) {
  .conversation {
    padding: 20px 30px;
    width: 90%;
  }

  form {
    width: 90%;
    bottom: 60px;
  }
}
</style>
