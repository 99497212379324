<template>
  <div class="blin"></div>
</template>

<style lang="less" scoped>
.blin {
  display: inline;
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  height: 18px;
  line-height: 30px;
}

.blin:after {
  position: absolute;
  content: '';
  width: 3px;
  height: 18px;
  top: 50%;
  transform: translateY(-50%); 
  animation: blink 1.2s infinite steps(1, start);
}

@keyframes blink {

  0%,
  100% {
    background-color: #444;
  }

  50% {
    background-color: transparent;
  }
}
</style>